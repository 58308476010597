import React, { useEffect } from "react";
import AlbumPromo from "./sections/album-promo/AlbumPromo";
import About from "./sections/bio/About";
import Videos from "./sections/videos/Videos";
import Contact from "./sections/contact/Contact";
import ScrollToTopButton from "./components/scroll-to-top/ScrollToTopButton";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import "./App.css";

// Import your language files
import enTranslation from "./locales/en.json";
import huTranslation from "./locales/hu.json";
import Header from "./header/Header";
import Footer from "./footer/Footer";
import ReactGA from "react-ga4";

// Initialize i18next
i18n.use(initReactI18next).init({
  lng: "en", // Set the default language
  resources: {
    en: {
      translation: enTranslation,
    },
    hu: {
      translation: huTranslation,
    },
  },
});

const App = () => {
  useEffect(() => {
    // Initialize GA4
    ReactGA.initialize("G-DBVXPYBSWQ");
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "Landing Page",
    });
  }, []);

  return (
    <>
      <Header />
      <div className="main-content">
        <AlbumPromo />
        <About />
        <Videos />
        <Contact />
        <ScrollToTopButton />
      </div>
      <Footer />
    </>
  );
};

export default App;
