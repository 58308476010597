import React from "react";
import "./About.css";
import { useTranslation } from "react-i18next";

const About = () => {
  const { t } = useTranslation();

  return (
    <section id="band">
      <div className="band-container">
        <div className="band-text">
          <h2>{t("bio.header")}</h2>
          <p>{t("bio.p1")}</p>
          <p>{t("bio.p2")}</p>
          <p>{t("bio.p3")}</p>
          <p>{t("bio.p4")}</p>
        </div>
        <div className="band-image">
          <img src={require("./assets/band.webp")} alt="Sons Left Behind" />
        </div>
      </div>
    </section>
  );
};

export default About;
