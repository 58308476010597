import React from "react";
import "./Header.css";
import LanguageSelector from "./language-selector/LanguageSelector";
import Menu from "./menu/Menu";

const Header = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <header id="header">
      <Menu />
      <div id="logo">
        <button onClick={scrollToTop} aria-label="Go to top">
          <img src={require("./assets/fiery_logo.webp")} alt="Band Logo" />
        </button>
      </div>
      <LanguageSelector />
    </header>
  );
};

export default Header;
