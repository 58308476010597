import React from "react";
import Slider from "react-slick";
import "./Videos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from "react-i18next";

const videos = [
  "sty74l92nsk",
  "0Kz-We0-qjs",
  "9uG3IwySv50",
  "xlcoAkfnQ4A",
  "VXEWtXr_h-4",
];

const Videos = () => {
  const { t } = useTranslation();

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    accessibility: true,
    arrows: false,
    swipe: true,
    swipeToSlide: true,
    draggable: true,
    touchMove: true,
  };

  return (
    <section id="videos" className="videos-section">
      <h1>{t("videos.header")}</h1>
      <div className="slider-container">
        <Slider {...settings}>
          {videos.map((video, index) => (
            <div key={index} className="video-container">
              <iframe
                src={`https://www.youtube-nocookie.com/embed/${video}`}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default Videos;
