import React from "react";
import "./Contact.css";
import { useTranslation } from "react-i18next";
import { FaRegEnvelope, FaPhone } from "react-icons/fa";

const Contact = () => {
  const { t } = useTranslation();

  return (
    <section id="contact">
      <h1>{t("contact.header")}</h1>
      <p className="contact-desc">{t("contact.description")}</p>

      <div className="contact-details">
        <h2>Booking</h2>
        <div className="contact-container">
          <FaPhone size={20} />
          <p className="contact-text">
            {t("contact.pepo")} - <a href="tel:+36204353524">+36 20 435 3524</a>
          </p>
        </div>
        <div>
          <h2>{t("contact.pressHeader")}</h2>
          <p className="contact-container">
            <FaRegEnvelope className="email-icon" size={26} />
            <a href="mailto:info@sonsleftbehind.com" className="contact-text">
              info@sonsleftbehind.com
            </a>
          </p>
          <br />
          <div className="contact-container">
            <FaPhone size={20} />
            <p className="contact-text">
              {t("contact.aron")} -{" "}
              <a href="tel:+36307295914">+36 30 729 5914</a>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
