import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./Menu.css";
import { FaBars, FaTimes } from "react-icons/fa";

const Menu = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (isOpen && !event.target.closest(".menu")) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen]);

  const scrollToSection = (id) => {
    const targetSection = document.getElementById(id);
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    setIsOpen(false);
  };

  return (
    <nav className="menu">
      <button className="menu-toggle" onClick={toggleMenu}>
        {isOpen ? <FaTimes /> : <FaBars />}
      </button>

      <ul className={`menu-links ${isOpen ? "open" : ""}`}>
        {["band", "videos", "contact"].map((id) => (
          <li key={id}>
            <button onClick={() => scrollToSection(id)}>
              {t(`menu.${id}`).toUpperCase()}
            </button>
          </li>
        ))}

        <li>
          <a
            href="https://www.facebook.com/sonsleftbehind/events"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("menu.shows").toUpperCase()}
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Menu;
