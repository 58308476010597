import React, { useState, useEffect } from "react";
import { FaAngleUp } from "react-icons/fa";
import "./ScrollToTopButton.css";

const ScrollToTopButton = () => {
  // State to handle button visibility
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Listen to scroll events
    const handleScroll = () => {
      if (window.scrollY > 20) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Scroll to top function
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    // Button container, conditionally rendered based on scroll position
    isVisible && (
      <button onClick={scrollToTop} className="scroll-to-top-btn">
        <FaAngleUp size={44} />
      </button>
    )
  );
};

export default ScrollToTopButton;
